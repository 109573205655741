import { Link } from 'react-router-dom'
import { PrimaryButton } from 'src/components/Button'
import { Row } from 'src/components/Flex'
import { PAIR_ADDRESS } from 'src/constants'
import { ThemedText } from 'src/theme/components'
import { styled } from 'styled-components'

const StyledHeader = styled(Row)`
  position: relative;
  top: 0;
  z-index: 1000;
  padding: 20px 24px;
  justify-content: space-between;
  align-items: center;
  max-width: ${({ theme }) => theme.maxWidth};
  margin: 0 auto;
  justify-content: space-between;
  height: ${({ theme }) => theme.headerHeight};
`

const Title = styled(ThemedText.Custom)`
  font-size: 38px;
  font-weight: 900;

  @media only screen and (min-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    font-size: 64px;
  }
`

export default function Header() {
  return (
    <StyledHeader>
      <Title>Drowzy - $Zzz</Title>

      <Row gap={12}>
        <Link to={`https://www.geckoterminal.com/starknet-alpha/pools/${PAIR_ADDRESS}`}>
          <PrimaryButton>Chart</PrimaryButton>
        </Link>
      </Row>
    </StyledHeader>
  )
}
