{/* prettier-ignore */}
import {isMobile} from 'react-device-detect';
import { Link } from 'react-router-dom'
import { Image } from 'rebass'
import background from 'src/assets/background.mp4'
import backmusic from 'src/assets/backmusic.mp3'
import familyMember0 from 'src/assets/family/family-member-0.png'
import familyMember1 from 'src/assets/family/family-member-1.png'
import familyMember2 from 'src/assets/family/family-member-2.png'
import familyMember3 from 'src/assets/family/family-member-3.png'
import { PrimaryButton } from 'src/components/Button'
import { Column } from 'src/components/Flex'
import { QUOTE_TOKEN_ADDRESS, TOKEN_ADDRESS } from 'src/constants'
import { ThemedText } from 'src/theme/components'
import styled from 'styled-components'

const TopSection = !isMobile ? styled.section`
  position: relative;
  margin-top: -${({ theme }) => theme.headerHeight};
  right: 0;
  bottom: 0;
  left: 0;
  height: 40vw;
  min-height: 420px;
  width: 100%;

  .videoTag {
    --mask: linear-gradient(to bottom, 
        rgba(0,0,0, 1) 0,   rgba(0,0,0, 1) 40%, 
        rgba(0,0,0, 0) 75%, rgba(0,0,0, 0) 0
    ) 100% 50% / 100% 100% repeat-x;
    -webkit-mask: var(--mask); 
    mask: var(--mask);
    width: 100%;
  }
` : styled.section`
  position: relative;
  margin-top: -${({ theme }) => theme.headerHeight};
  right: 0;
  bottom: 0;
  left: 0;
  height: 40vw;
  width: 100%;

  .videoTag {
    --mask: linear-gradient(to bottom, 
        rgba(0,0,0, 1) 0,   rgba(0,0,0, 1) 40%, 
        rgba(0,0,0, 0) 75%, rgba(0,0,0, 0) 0
    ) 100% 50% / 100% 100% repeat-x;
    -webkit-mask: var(--mask); 
    mask: var(--mask);
    width: 100%;
  }
`

const FirstArticleTopSection = !isMobile ? styled.article`
  position: absolute;
  bottom: 32px;
  width: 100%;
  padding: 0 16px;

  @media only screen and (min-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    bottom: 64px;
  }

  @media only screen and (min-width: ${({ theme }) => `${theme.breakpoint.xxl}px`}) {
    bottom: 128px;
  }
` : styled.article`
  position: absolute;
  bottom: -40px;
  width: 100%;
  padding: 0 16px;

  @media only screen and (min-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    bottom: 64px;
  }

  @media only screen and (min-width: ${({ theme }) => `${theme.breakpoint.xxl}px`}) {
    bottom: 128px;
  }
`

// Second section

const Section = styled.section`
  width: 100%;
  padding: 16px 12px 96px;
  max-width: ${({ theme }) => theme.maxWidth};
  margin: 0 auto 0;

  @media only screen and (min-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    padding-top: 16px;
  }

  @media only screen and (min-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    padding-top: 0;
  }
`

// First article

const FirstArticle = styled.div`
  padding-top: 55px;
  width: 100%;
`

const FirstArticleText = !isMobile ? styled(ThemedText.BodyPrimary)`
  font-size: 26px;
  text-align: center;
  line-height: 54px;
  float: left;
  max-width: 50%;
  margin-right: 78px !important;
  padding-top: 25px;

  @media only screen and (min-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    font-size: 32px !important;
  }
` : styled(ThemedText.BodyPrimary)`
  font-size: 26px;
  text-align: center;
  float: left;
  width: 100%;

  @media only screen and (min-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    font-size: 32px !important;
  }
`

// Second article

const SecondArticle = !isMobile ? styled(Column)`
  margin-top: 80px;
  gap: 32px;

  @media only screen and (min-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    margin-top: 100px;
  }

  > p {
    font-size: 18px;
    text-align: center;
    width: 600px;
  }
` : styled(Column)`
  margin-top: 80px;
  gap: 32px;

  @media only screen and (min-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    margin-top: 100px;
  }

  > p {
    font-size: 18px;
    text-align: center;
  }
`

const FamilyMembersContainer = styled(Column)`
  gap: 16px;
  padding: 0 32px;

  @media only screen and (min-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    flex-direction: row;
  }
`

const FamilyMembersDesc = styled.div`
  display: flex;
`

const FamilyMemberText = styled.div`
  flex-basis: 100%;
  white-space: nowrap;
  text-align: center;
  margin: 0 15px;
  width: 345px;

  > p {
    white-space: break-spaces;
  }
`

const FamilyMember = styled(Image)`
  border: 10px ${({ theme }) => theme.bg1} solid;
  box-shadow: 0 0 2px 2px ${({ theme }) => theme.neutral1};
  border-radius: 2px;
  transform: skewX(2deg);
`

const MeetFamily = styled(ThemedText.HeadlineMedium)`
  font-size: 64px !important;
  text-align: center;
  line-height: 40px;

  @media only screen and (min-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    font-size: 100px !important;
    line-height: normal !important;
  }
`

export default function HomePage() {
  if (!isMobile) {
    return (
      <>
        <audio autoPlay loop> 
          <source src={backmusic} type="audio/mp3"/>
        </audio>
        <TopSection>
          <video className='videoTag' autoPlay loop muted>
            <source src={background} type='video/mp4' />
          </video>
          <FirstArticleTopSection>
            <Column gap={16} alignItems="center" justify="center">
              <Link
                to={`https://app.avnu.fi/en?tokenFrom=${QUOTE_TOKEN_ADDRESS}&tokenTo=${TOKEN_ADDRESS}`}
                target="_blank"
              >
                <PrimaryButton>Buy token $Zzz</PrimaryButton>
              </Link>
            </Column>
          </FirstArticleTopSection>
        </TopSection>

        <Section>
          <FirstArticle>
            <FirstArticleText>
              Drowzy was launched through Unruggable meme, a deployment tool designed by the Starknet exploration team to ensure safety for memecoin traders.
              <br/>
              <br/>
              $ZZZ is on a mission to onboard the next 1M Sloths into the Starknet promised land for memecoin season
            </FirstArticleText>
            <Image src={familyMember0} />
          </FirstArticle>

          <SecondArticle>
            <MeetFamily>Meet the team</MeetFamily>
            <p>
              In the heart of an otherworldly forest, where the boundary between dreams and reality blurs, resides the Dreamweaver family
            </p>

            <FamilyMembersContainer>
              <FamilyMember src={familyMember3} />
              <FamilyMember src={familyMember2} />
              <FamilyMember src={familyMember1} />
            </FamilyMembersContainer>
            <FamilyMembersDesc>
              <FamilyMemberText>
                DEGEN BROTHER<br/>
                <strong>Finnick Shadows</strong><br/>

                <p>
                A sloth with the flair of a mad scientist, his fur is a chaotic blend of dark shades, with streaks of white that flash like lightning. His eyes, wide and feverish, gleam with the thrill of his next &quot;experiment.&quot; Often shrouded in a cloak that&apos;s seen better days, he revels in the night, concocting schemes that are as brilliant as they are bewildering
                </p>
              </FamilyMemberText>
              <FamilyMemberText>
                THE MATRIARCH<br/>
                <strong>Vivienne Myst</strong><br/>

                <p>
                The matriarch is a vision of gothic grace. Her fur, styled in Victorian elegance, is accented with lace and velvet, creating an aura of mystique. She is the keeper of ancient tales and secrets, a bridge between the past and the present. Her eyes, though soft, carry the weight of secrets untold, hinting at a past as mysterious as the moon.
                </p>
              </FamilyMemberText>
              <FamilyMemberText>
                THE PATRIARCH<br/>
                <strong>Cornelius Ember</strong><br/>

                <p>
                This patriarch is a sloth of few words, preferring the company of his ancient, leather-bound books to the wild shenanigans of his family. He&apos;s the anchor in the storm of his family&apos;s creativity, providing silent support with a knowing smile that suggests he&apos;s not as serious as he appears. His is the hand that steadies the ship, ensuring that no matter how wild the voyage, the family always finds its way home.
                </p>
              </FamilyMemberText>
            </FamilyMembersDesc>
          </SecondArticle>

          <SecondArticle>
            <MeetFamily>Drowzy - $ZZZ</MeetFamily>
          </SecondArticle>
        </Section>
      </>
    )
  }
  return (
    <>
      <audio autoPlay loop> 
        <source src={backmusic} type="audio/mp3"/>
      </audio>
      <TopSection>
        <video className='videoTag' autoPlay loop muted>
          <source src={background} type='video/mp4' />
        </video>
        <FirstArticleTopSection>
          <Column gap={16} alignItems="center" justify="center">
            <Link
              to={`https://app.avnu.fi/en?tokenFrom=${QUOTE_TOKEN_ADDRESS}&tokenTo=${TOKEN_ADDRESS}`}
              target="_blank"
            >
              <PrimaryButton>Buy token $Zzz</PrimaryButton>
            </Link>
          </Column>
        </FirstArticleTopSection>
      </TopSection>

      <Section>
        <FirstArticle>
          <FirstArticleText>
            Drowzy was the first token launched through Unruggable meme, a deployment tool designed by the Starknet exploration team to ensure safety for memecoin traders.
            <br/>
            <br/>
            $ZZZ is on a mission to onboard the next 1M Sloths into the Starknet promised land for memecoin season
          </FirstArticleText>
          <Image src={familyMember0} />
        </FirstArticle>

        <SecondArticle>
          <MeetFamily>Meet the team</MeetFamily>
          <p>
            In the heart of an otherworldly forest, where the boundary between dreams and reality blurs, resides the Dreamweaver family
          </p>

          <FamilyMembersContainer>
            <FamilyMember src={familyMember3} />
          </FamilyMembersContainer>
          <FamilyMembersDesc>
            <FamilyMemberText>
              DEGEN BROTHER<br/>
              <strong>Finnick Shadows</strong><br/>

              <p>
              A sloth with the flair of a mad scientist, his fur is a chaotic blend of dark shades, with streaks of white that flash like lightning. His eyes, wide and feverish, gleam with the thrill of his next &quot;experiment.&quot; Often shrouded in a cloak that&apos;s seen better days, he revels in the night, concocting schemes that are as brilliant as they are bewildering
              </p>
            </FamilyMemberText>
          </FamilyMembersDesc>
          <FamilyMembersContainer>
            <FamilyMember src={familyMember2} />
          </FamilyMembersContainer>
          <FamilyMembersDesc>
            <FamilyMemberText>
              THE MATRIARCH<br/>
              <strong>Vivienne Myst</strong><br/>

              <p>
              The matriarch is a vision of gothic grace. Her fur, styled in Victorian elegance, is accented with lace and velvet, creating an aura of mystique. She is the keeper of ancient tales and secrets, a bridge between the past and the present. Her eyes, though soft, carry the weight of secrets untold, hinting at a past as mysterious as the moon.
              </p>
            </FamilyMemberText>
          </FamilyMembersDesc>
          <FamilyMembersContainer>
            <FamilyMember src={familyMember1} />
          </FamilyMembersContainer>
          <FamilyMembersDesc>
            <FamilyMemberText>
              THE PATRIARCH<br/>
              <strong>Cornelius Ember</strong><br/>

              <p>
              This patriarch is a sloth of few words, preferring the company of his ancient, leather-bound books to the wild shenanigans of his family. He&apos;s the anchor in the storm of his family&apos;s creativity, providing silent support with a knowing smile that suggests he&apos;s not as serious as he appears. His is the hand that steadies the ship, ensuring that no matter how wild the voyage, the family always finds its way home.
              </p>
            </FamilyMemberText>
          </FamilyMembersDesc>
        </SecondArticle>

        <SecondArticle>
          <MeetFamily>Drowzy - $ZZZ</MeetFamily>
        </SecondArticle>
      </Section>
    </>
  )
}
