import { styled } from 'styled-components'

const Base = styled.button`
  font-size: 28px;
  font-weight: 600;
  color: ${({ theme }) => theme.neutral1};
  cursor: pointer;
  padding: 10px 24px;
  width: auto;
  outline: none;
  min-width: 150px;
  border-radius: 28px;
  font-family: 'AliceInWonderland';

  @media only screen and (min-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    font-size: 38px;
    padding: 12px 34px;
    border-radius: 42px;
  }
`

export const PrimaryButton = styled(Base)`
  background: ${({ theme }) => theme.neutral1};
  border: 2px solid ${({ theme }) => theme.bg1};
  color: ${({ theme }) => theme.bg1};
  transition: 125ms;

  &:hover {
    background: ${({ theme }) => theme.bg1};
    border: 2px solid ${({ theme }) => theme.neutral1};
    color: ${({ theme }) => theme.neutral1};
  }
`
